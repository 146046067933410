var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center justify-end pb-20 text-center w-full col-span-1 md:col-span-10"},[_c('tabs-component-router',{attrs:{"beforeRoute":'superdoctor/doctor/summary',"afterRoute":"" + (_vm.$route.params.user_id) +
        (_vm.$route.query.block ? ("?block=" + (_vm.$route.query.block)) : ''),"tabs":[
      {
        key: 'clinic',
        label: 'ficha clínica',
      },
      {
        key: 'data',
        label: 'datos del paciente',
      },
      {
        key: 'form',
        label: 'primera consulta',
      } ]}}),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }