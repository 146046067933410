<template>
  <div class="w-full flex justify-start items-center mb-4">
    <div class="sm:hidden w-full">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
        @change="
          event => {
            goTo(event.target.value);
          }
        "
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      >
        <option
          :selected="isCurrentTab(tab.key)"
          v-for="(tab, tabIndex) in tabs"
          :key="tabIndex"
          :value="tab.key"
          >{{ tab.label }}</option
        >
      </select>
    </div>
    <div class="hidden sm:block  w-full">
      <div class="border-b border-gray-400">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
          <button
            v-for="(tab, tabIndex) in tabs"
            @click="goTo(tab.key)"
            :key="tabIndex"
            :class="
              isCurrentTab(tab.key)
                ? 'border-green-600 text-green-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize'
            "
            type="button"
          >
            {{ tab.label }}
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsRouter',
  props: {
    tabs: {
      type: Array,
      required: () => [],
    },
    beforeRoute: {
      type: String,
      default: '',
    },
    afterRoute: {
      type: String,
      default: '',
    },
  },
  methods: {
    goTo(tab) {
      let nameArray = this.$route.name.split('/');
      nameArray[nameArray.length - 1] !== tab &&
        this.$router.push({
          path: `/${this.beforeRoute}/${tab}/${this.afterRoute}`,
        });
    },
    isCurrentTab(tab) {
      let nameArray = this.$route.name.split('/');
      return nameArray[nameArray.length - 1] === tab;
    },
  },
};
</script>
