<template>
  <div
    class="flex flex-col items-center justify-end pb-20 text-center w-full col-span-1 md:col-span-10"
  >
    <tabs-component-router
      :beforeRoute="'superdoctor/doctor/summary'"
      :afterRoute="
        `${$route.params.user_id}` +
          ($route.query.block ? `?block=${$route.query.block}` : '')
      "
      :tabs="[
        {
          key: 'clinic',
          label: 'ficha clínica',
        },
        {
          key: 'data',
          label: 'datos del paciente',
        },
        {
          key: 'form',
          label: 'primera consulta',
        },
      ]"
    />
    <router-view />
  </div>
</template>

<script>
import TabsRouter from '@/components/navigation/TabsRouter';

export default {
  name: 'Summary',
  components: {
    'tabs-component-router': TabsRouter,
  },
};
</script>
